$(document).on('turbolinks:load', ()=> {
  $('#add-new-image').on('click', ()=> $('#images').trigger('click'));

  $('#product-save-button').on('click', () => {
    let price = $('#product_price_net').val().replace(',', '.');

    if (price == 0 || price == '' ) {
      $('#zero_price_warning').modal('show');
      return false;
    }

    $('#product_price_net').val(price);

    return true;
  });

  $('#confirm-price').on('click', () => $('#price-form').submit());

  $('input.checkbox.position').on('change', (e) => {
    $('input.checkbox.position').not(e.target).prop('checked', false);
  });

  $('#price-form input[type=number]').on('focus', (e)=> {
    $(e.target).on('wheel', (e) => e.preventDefault() );
  });

  $('.modal').on('hidden.bs.modal shown.bs.modal', function(){
      hideErrorAndFormatScheduledDate();
  });

  $('#world-form input[type="radio"][name="product[category_number]"]').on('change', (e)=> {
    let categoryNumber = e.target.id.split('number_')[1];
    let categoryProperties = `[data-category-numbers~="${categoryNumber}"]`;
    let otherCategoryProperties = `.product-property:not([data-category-numbers=""]):not(${categoryProperties})`;

    $(`.product-property${categoryProperties}`).toggleClass('hidden', false);
    $(otherCategoryProperties).toggleClass('hidden', true);

    toggleRecommendedShopCategories()
  });

  $('#world-form input[type="checkbox"][name="product[meta_categories][]"]').on('change', (e)=> {
    toggleRecommendedShopCategories();
  });

  $('#product_price_net').keydown(function (e) {
    let separator = ',';

    if ($('html').attr('lang') == 'en') {
      separator = '.';
    }

    if (
        [
          'Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'F5', 'Shift',
          'Home', 'End', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'
        ].includes(e.key) ||
        // Only single separator is allowed.
        (e.key == separator && this.value.indexOf(separator) == -1) ||
        // Ctrl/cmd+A, Ctrl/cmd+C, Ctrl/cmd+X.
        (['KeyA', 'KeyC', 'KeyX'].includes(e.code) && (e.ctrlKey === true || e.metaKey === true)) ||
        (
          ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key) &&
          // Not more than 2 digits after a decimal separator is allowed.
          (this.value.indexOf(separator) == -1 || (this.value.length - this.value.indexOf(separator)) <= 2)
        )
      ) {
      return;
    }

    e.preventDefault();
  });

  $('#product_reduced_vat').on('change', ()=> {
    calculateGrossPrice()
  });

  $('#product_price_net').on('input', ()=> {
    calculateGrossPrice()
  });

  toggleRecommendedShopCategories();
  calculateGrossPrice();
});

function hideErrorAndFormatScheduledDate() { $('.schedule-error').html(''); };

function toggleRecommendedShopCategories() {
  if (
    $('#product_category_number_product_category_160').is(':checked') ||
      (
        $('#product_category_number_product_category_166').is(':checked') &&
          $('#product_meta_categories_sew').is(':checked')
      )
  ) {
    $('.product_recommended_shop_category_ids').toggleClass('hidden', false)
  }
  else {
    $('.product_recommended_shop_category_ids').toggleClass('hidden', true)
  }
};

function calculateGrossPrice() {
  if ($('#product_price_gross').length > 0) {
    let separator = ',';

    if ($('html').attr('lang') == 'en') {
      separator = '.';
    }

    let taxPercentage = $('.price-box').data()['tax']

    if (document.getElementById('product_reduced_vat') && document.getElementById('product_reduced_vat').checked) {
      taxPercentage = $('.price-box').data()['taxReduced']
    }

    $('#product_price_gross').val(
      String((document.getElementById('product_price_net').value.replace(',', '.') * (100 + taxPercentage) / 100).toFixed(2)).replace('.', separator)
    );
  }
};
