import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "left", "scroll", "right" ]

  connect() {
    this.leftTarget.addEventListener("click", this.scrollLeft)
    this.rightTarget.addEventListener("click", this.scrollRight)
    this.updateVisibility()
    const resizeObserver = new ResizeObserver(() =>  {
      this.updateVisibility()
  })

    resizeObserver.observe(document.body)
  }

  updateVisibility() {
    if (this.scrollTarget.scrollWidth <= this.scrollTarget.clientWidth) {
      this.leftTarget.style.display = "none"
      this.rightTarget.style.display = "none"
      this.element.style.marginLeft = "0"
      this.element.style.marginRight = "0"
    } else {
      this.leftTarget.style.display = null
      this.rightTarget.style.display = null
    }
  }

  scrollLeft = (event) => {
    event.preventDefault()
    this.scrollTarget.scrollTo({
      left: this.scrollTarget.scrollLeft - 280,
      behavior: "smooth"
     })
  }

  scrollRight = (event) => {
    event.preventDefault()
    this.scrollTarget.scrollTo({
      left: this.scrollTarget.scrollLeft + 280,
      behavior: "smooth"
     })
  }
}
